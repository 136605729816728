import { Button } from '@arckit/ui'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import { Link, PageProps } from 'gatsby'
import React from 'react'
import Resource from '../components/global/Resource'

export interface LocationState {
	firstName: string
	params: string
	showPricingGuide: boolean
	showSummerPricingGuide: boolean
}

export default function ThankYouPage({ location }: PageProps) {
	const locationState = location.state as LocationState

	return (
		<Layout pageName="Thank You">
			<Section full className="pb-0">
				<Row className="bg-cover bg-center bg-no-repeat py-20" style={{ backgroundImage: `url('/images/thank-you/hero.jpg')`, height: 600 }}>
					<Col width="w-full md:w-5/6 lg:w-1/2" className="pl-4 text-white md:pl-16">
						<h1 className="font-cursive mb-3 text-6xl sm:text-8xl">Thank You!</h1>
						{locationState ? (
							locationState.firstName ? (
								<p data-cy="thank-you-p" className="mb-6 text-xl text-white">
									{locationState.firstName}, thank you for requesting more information from American Reading Company. Your ARC Account Manager will be in contact
									with you shortly.
								</p>
							) : (
								<p className="mb-6 text-xl text-white">
									Thank you for requesting more information from American Reading Company. Your ARC Account Manager will be in contact with you shortly.
								</p>
							)
						) : (
							<p className="mb-6 text-xl text-white">
								Thank you for requesting more information from American Reading Company. Your ARC Account Manager will be in contact with you shortly.
							</p>
						)}
						{locationState
							? locationState.showPricingGuide && (
									<Resource title="Pricing Guide" linkOnly>
										<div className="mb-6">
											<Button variant="light">Download Pricing Guide</Button>
										</div>
									</Resource>
							  )
							: null}
						{locationState
							? locationState.showSummerPricingGuide && (
									<Resource title="Summer Pricing Guide" linkOnly>
										<div className="mb-6">
											<Button variant="light">Download Summer Pricing Guide</Button>
										</div>
									</Resource>
							  )
							: null}
						<Link to="/" className="hover:no-underline">
							<Button variant="light">Return to Home Page</Button>
						</Link>
					</Col>
				</Row>
			</Section>
			{locationState && locationState.params && <iframe src={`https://info.americanreading.com/l/1029901/2023-08-07/2qgm?${locationState.params}`} width={1} height={1} />}
		</Layout>
	)
}
